import ZbAddTableModal from './zb-add-table-modal'
import ZbAddEnvironmentModal from './zb-add-environment-modal'
import ZbAddMapModal from './zb-add-map-modal'
import ZbMixinTables from './ZbMixinTables'
import ZbMixinMaps from './ZbMixinMaps'

export default {
  name: 'ZbTablesEnvs',
  components: {
    ZbAddTableModal,
    ZbAddEnvironmentModal,
    ZbAddMapModal
  },
  mixins: [
    ZbMixinTables,
    ZbMixinMaps
  ],
  data () {
    return {
      tab: null,
      showAddTable: false,
      showEnvironmentModal: false,
      showAddMap: false,
      areaTables: [],
      areaId: ''
    }
  },
  methods: {
    async reloadTable () {
      this.initializeArea()
      this.showAddTable = false
    },
    async reloadEnv () {
      this.initializeArea()
      this.showEnvironmentModal = false
    },
    async reloadMap () {
      this.initializeMap()
      this.showAddMap = false
    },
    closeAddTableModal () {
      this.showAddTable = false
    },
    openAddTable (areaId) {
      this.showAddTable = !this.showAddTable
      this.areaId = areaId
    },
    openAddEnv () {
      this.showEnvironmentModal = !this.showEnvironmentModal
    },
    openAddMap () {
      this.showAddMap = !this.showAddMap
    }
  }
}
