import { ServiceFactory } from '@/services/ServiceFactory'
const AreaTableService = ServiceFactory.get('AreaTableService')
export default {
  name: 'ZbAddTableModal',
  data () {
    return {
      postData: {
        areaName: ''
      }
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      default: false
    },
    seeDetails: {
      type: Object
    }
  },
  watch: {
    value (newVal, oldVal) {
      try {
        if (newVal) {
          this.$refs.observer2.reset()
        }
      } catch (error) {}
    }
  },
  methods: {
    dialogEvent () {
      this.clear()
      this.$emit('reload', this.seeDetails)
    },
    async save () {
      try {
        await AreaTableService.postArea(this.postData)
        this.$notify({
          title: 'Exito:',
          type: 'success',
          text: 'El ambiente se creó correctamente'
        })
        this.dialogEvent()
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error
        })
      }
    },
    clear () {
      this.postData = {
        areaName: ''
      }
      this.$refs.observer2.reset()
    }
  }
}
