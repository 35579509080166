import { ServiceFactory } from '@/services/ServiceFactory'
const MapService = ServiceFactory.get('MapService')

export default {
  name: 'ZbAddMapModal',
  data () {
    return {
      imageMap: null,
      loading: false,
      preview: {
        map: null
      },
      nameMap: ''
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  watch: {
    value () {
      this.preview = {
        map: null
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'fas fa-trash'
    }
  },
  methods: {
    async submitMap () {
      if (this.imageMap && this.nameMap !== '') {
        try {
          this.loading = true
          const newMap = new FormData()
          newMap.append('map', this.imageMap[0])
          newMap.append('name', this.nameMap)
          const { success, msg } = await MapService.post(newMap)
          this.loading = false
          if (success) {
            this.$notify({
              title: 'Éxito',
              type: 'success',
              text: msg
            })
            this.dialogEvent()
          }
        } catch (e) {
          this.loading = false
          this.$notify({
            title: 'Error',
            type: 'error',
            text: e.msg
          })
        }
      }
    },
    dialogEvent () {
      this.loading = false
      this.nameMap = ''
      this.imageMap = null
      this.preview.map = null
      this.$emit('reload')
    },
    handleBannerUpload (e) {
      this.imageMap = e.target.files
      this.previewBanner(e)
    },
    previewBanner (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.preview.map = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    }
  }
}
