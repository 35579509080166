import { ServiceFactory } from '@/services/ServiceFactory'
const AreaTableService = ServiceFactory.get('AreaTableService')
export default {
  name: 'ZbAddTableModal',
  data () {
    return {
      postData: {
        zbBarAreaId: this.areaId || '',
        numberChairs: '',
        numberTable: ''
      }
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      default: false
    },
    areaId: {
      type: String,
      default: ''
    }
  },
  methods: {
    dialogEvent () {
      this.clear()
      this.$emit('reload')
    },
    async save () {
      try {
        this.postData.zbBarAreaId = this.areaId
        this.postData.numberChairs = Number(this.postData.numberChairs)
        this.postData.numberTable = Number(this.postData.numberTable)
        await AreaTableService.postTable(this.postData)
        this.$notify({
          title: 'Exito:',
          type: 'success',
          text: 'Nueva mesa creada correctamente'
        })
        this.postData = {
          zbBarAreaId: this.areaId || '',
          numberChairs: null,
          numberTable: null
        }
        this.dialogEvent()
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    },
    clear () {
      this.postData = {
        zbBarAreaId: this.areaId || '',
        numberChairs: '',
        numberTable: ''
      }
      this.$refs.observer.reset()
    }
  }
}
