import { ServiceFactory } from '@/services/ServiceFactory'
const MapService = ServiceFactory.get('MapService')

export default {
  data () {
    return {
      dialogCompleted: false,
      loadingMaps: false,
      maps: []
    }
  },
  watch: {
    tab () {
      if (this.tab === 1) {
        this.initializeMap()
      }
    }
  },
  methods: {
    async initializeMap () {
      try {
        this.loading = true
        const { success, businessMap } = await MapService.get()
        this.loading = false
        if (success) {
          this.maps = businessMap
        }
      } catch (error) {
        this.loading = false
      }
    },
    destroyMap (mapId) {
      this.$swal({
        title: this.$t('maps.deleteQuestion'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('delete.confirm'),
        cancelButtonText: this.$t('delete.cancel')
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await MapService.delete(mapId)
            this.$swal(
              this.$t('delete.deleted'),
              this.$t('delete.successMessage'),
              'success'
            ).then(async (result) => {
              this.reloadMap()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.msg
            })
          }
        }
      })
    }
  }
}
