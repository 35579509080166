import { ServiceFactory } from '@/services/ServiceFactory'
const AreaTableService = ServiceFactory.get('AreaTableService')

export default {
  data () {
    return {
      dialogCompleted: false,
      loadingTables: false,
      areaTables: []
    }
  },
  watch: {
    tab () {
      if (this.tab === 0) {
        this.initializeArea()
      }
    }
  },
  methods: {
    async initializeArea () {
      try {
        this.loading = true
        const { success, barAreas } = await AreaTableService.get()
        this.loading = false
        if (success) {
          this.areaTables = barAreas
        }
      } catch (error) {
        this.loading = false
      }
    },
    closeModal () {
      this.showTableOrdersModal = false
    },
    openAddTable (id) {
      this.areaId = id
      this.showTableOrdersModal = true
    },
    openAddArea () {
      this.showEnvironmentModal = true
    },
    destroyArea (areaId) {
      this.$swal({
        title: this.$t('tableOrders.deleteQuestion'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('delete.confirm'),
        cancelButtonText: this.$t('delete.cancel')
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await AreaTableService.deleteArea(areaId)
            this.$swal(
              this.$t('delete.deleted'),
              this.$t('delete.successMessage'),
              'success'
            ).then(async (result) => {
              this.reloadEnv()
            })
          } catch (error) {
            this.$notify({
              title: 'Error:',
              type: 'error',
              text: error.msg
            })
          }
        }
      })
    },
    async destroyTable (tableId) {
      try {
        await AreaTableService.deleteTable(tableId)
        this.$notify({
          title: 'Eliminado',
          type: 'success',
          text: 'Mesa eliminada correctamente.'
        })
        this.reloadTable()
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
      }
    }
  }
}
